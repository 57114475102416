import { Trans } from "@lingui/macro"
import { gettext } from "@multimediallc/web-utils"
import { Typography } from "../../common"
import "./OneClickFlowErrorPage.scss"

const i18n = {
    purchaseError: gettext("Purchase error"),
    errorProcessing: gettext(
        "There was an error while processing your payment",
    ),
    tryEpochMessage: gettext(
        "Your purchase was declined. Please try the backup credit card processor <b>Epoch</b> or a different payment method.",
    ),
}

export function OneClickFlowErrorPage({
    openPurchasePage,
    tryEpochMessage = false,
}: {
    openPurchasePage: () => void
    tryEpochMessage?: boolean
}) {
    const errorMessage = tryEpochMessage
        ? i18n.tryEpochMessage
        : i18n.errorProcessing

    return (
        <>
            <Typography
                className="one-click-flow__scene-title"
                size="xlpx"
                weight="bold"
            >
                {i18n.purchaseError}
            </Typography>
            <div className="one-click-flow__error-page">
                <Typography
                    className="md-error-text"
                    size="mdpx"
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                />
                <Typography
                    className="sm-error-text"
                    size="smpx"
                    style={{ marginTop: "15px" }}
                >
                    <Trans>
                        Go to the{" "}
                        <a onClick={openPurchasePage} href="#" target="_blank">
                            purchase page
                        </a>{" "}
                        for more info.
                    </Trans>
                </Typography>
            </div>
        </>
    )
}
