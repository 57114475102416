import { mergeClasses } from "../../../../utils/css"
import "./InputRadio.scss"

interface InputRadioProps extends React.ComponentPropsWithRef<"input"> {
    name: string
    checked?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    label: React.ReactNode
    id: string
}

export const InputRadio = ({
    id,
    checked,
    onChange,
    label,
    className,
    ...other
}: InputRadioProps) => {
    return (
        <label className={mergeClasses("input-radio", className)} htmlFor={id}>
            <input
                id={id}
                type="radio"
                checked={checked}
                onChange={onChange}
                {...other}
            />
            {label}
        </label>
    )
}
